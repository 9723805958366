import React from "react";
import Img from "gatsby-image";

export default function Project({ project }) {
  console.log('project', project);
  return (
    <div className="row single-project">
      <div className="col-12 col-md-6 text-center text-md-right">
        <Img className="img-fluid" fixed={project.picture.childImageSharp.fixed} />
      </div>
      <div className="col-12 col-md-6 text-center text-md-left">
        <h4 className="color-lavender">
          {project.link ? (
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              {project.name}
              <i className="fas fa-external-link-alt position-absolute" />
            </a>
          ) : project.name}
        </h4>
          {project.description && <p className="description">{project.description}</p>}
          <p>
          {project.tech && project.tech.map(tech => (
            <span
              className="btn btn-tech"
              key={tech}
            >
            {tech}
            </span>
          ))
          }
          </p>
          {project.resume && <p className="resume">{project.resume}</p>}
      </div>
    </div>
  );
}
