import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import PageHeader from "../components/PageHeader/PageHeader";
import ProudClients from "../components/ProudClients/ProudClients";
import Projects from "../components/Projects/Projects";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

class WorkPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={`Our Work | ${config.siteTitle}`} />
        <SEO />
        <Header />
        <PageHeader
          title="Our Work"
        />
        <Projects />
        <ProudClients />
        <Footer />
      </Layout>
    );
  }
}

export default WorkPage;
