import React from "react";
import { StaticQuery, graphql } from "gatsby"
import Project from "./Project";

function Projects({
  data: {
    allProjectsYaml: { edges },
  },
}) {
  return (
    <section className="container-fluid bg-white py-5 projects-section bg-white">
      <div className="container py-3">
        {edges.map(edge => (
          <Project
            key={edge.node.id}
            project={edge.node}
          />
        ))}
      </div>
    </section>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query ProjectsQuery {
        allProjectsYaml {
          edges {
            node {
              id
              name
              short
              link
              description
              resume
              tech
              picture {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Projects data={data} {...props} />}
  />
)
