/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "gatsby";

import aeotekImg from "../../assets/images/companies/white/aerotek.png";
import varixImg from "../../assets/images/companies/white/varix.png";
import tmaImg from "../../assets/images/companies/white/tma.png";
import provencredImg from "../../assets/images/companies/white/provencred.png";

export default function ProudClients() {
  return (
    <section className="container-fluid proud-clients-section py-5 color-white bg-gradient-blueberry-lavender">
      <div className="container py-3">

        <div className="row justify-content-center">
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="Aerotek" src={aeotekImg} />
          </div>
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="The Mark Agency" src={tmaImg} />
          </div>
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="Sensebyte" src={varixImg} />
          </div>
          <div className="col-auto my-3 mx-3 single-client">
            <img alt="Provencred" src={provencredImg} />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
            <p>
              We’re proud to have launched a lot of products for clients such as AeroTek, TMA, Vairix, Provencred to name a few.
            </p>
          </div>

          <div className="col-12 mt-5 text-center">
            <Link to="/contact">
              <button type="button" className="btn btn-outline-white">Lets talk about your project</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
